<template>
	<div>
		<div class="oneSoftWare">
			<el-tag effect="dark" size="mini" :disable-transitions="true" class="softTag">
				{{infoDetails.tagName}}
			</el-tag>

			<div class="softWareContent">
				<el-image style="width: 100px; height: 100px" :src="infoDetails.imgUrl" fit="fit"></el-image>

				<p class="softWareName" v-if="infoDetails.isServer == 0">{{infoDetails.name}}</p>
				<p class="softWareInfo" v-if="infoDetails.isServer == 0">
					{{infoDetails.details}}
				</p>

				<div class="isServer" v-if="infoDetails.isServer == 1">
					<p>在线时间:</p>
					<p>10:00 ~ 21:00</p>
					<p>企业QQ:</p>
					<p>888888888888</p>
				</div>

				<el-button type="primary" round plain="" size="small" style="margin-top: 20px;width: 80%;"
					v-if="infoDetails.isServer == 0" @click="downLoadSoftClick">点击下载</el-button>
				<el-button type="primary" round plain="" size="small" style="margin-top: 20px;width: 80%;"
					v-if="infoDetails.isServer == 1">点击咨询</el-button>

				<el-link type="primary" style="color: #FFFFFF;margin-top: 10px;" @click="downLoadSoftClick32()" v-if="infoDetails.isOtherVersion == 1">
					下载32位</el-link>
				<el-link type="primary" style="color: #FFFFFF;margin-top: 10px;" @click="downLoadSoftClick32()" v-if="infoDetails.isOtherVersion == 2">
					下载Mac版</el-link>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		downLoadSoft
	} from "@/api/index.js"
	export default {
		props: ["infoDetails"],
		data() {
			return {
				// url: require('@/assets/avatar.png')
			}
		},
		methods: {
			downLoadSoftClick() {
				// console.log(this.infoDetails)
				let a = document.createElement('a')
				a.href = `http://www.wancode.net:8010/wancode/exam/file/server/download?type=${this.infoDetails.downLodeType}`
				a.click();
			},
			downLoadSoftClick32() {
				// console.log(this.infoDetails)
				let a = document.createElement('a')
				a.href = `http://www.wancode.net:8010/wancode/exam/file/server/download?type=${this.infoDetails.downLodeType32}`
				a.click();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.oneSoftWare {
		position: relative;
		padding: 40px 20px;
		height: 300px;
		// border-radius: 10px;
		background: -moz-linear-gradient(left top, rgba(65, 105, 225, 0.3) 0%, #4169E1 100%);
		// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(65,105,225, 0.3)), color-stop(100%, #4169E1));
		background: -webkit-linear-gradient(left top, rgba(65, 105, 225, 0.3) 0%, #4169E1 100%);
		background: -o-linear-gradient(left top, rgba(65, 105, 225, 0.3) 0%, #4169E1 100%);
		background: -ms-linear-gradient(left top, rgba(65, 105, 225, 0.3) 0%, #4169E1 100%);
		background: linear-gradient(to left bottom, rgba(65, 105, 225, 0.3) 0%, #4169E1 100%);

		.softTag {
			position: absolute;
			top: 0;
			left: 0;
		}

		 ::v-deep.el-tag--mini {
			line-height: 20px;
		}

		 ::v-deep.el-tag {
			border-width: 0px;
			border-style: none;
			border-radius: 0;
		}

		.softWareContent {
			width: 100%;
			height: 100%;
			text-align: center;

			.softWareName {
				color: #FFFFFF;
				font-size: 18px;
				font-weight: 800;
			}

			.softWareInfo {
				color: #FFFFFF;
				font-size: 14px;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				line-height: 20px;
				height: 100px;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				line-clamp: 5;
				-webkit-box-orient: vertical;
			}

			.isServer {
				height: 120px;

				p {
					color: #FFFFFF;
					font-size: 20px;
					font-weight: 800;
				}
			}
		}
	}
</style>
